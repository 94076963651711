import { NavLink } from "react-router-dom";
import bgCalculator from "../src/assets/bgCalculator.svg";
import bpholio from "../src/assets/logonew.svg";

import "./App.css";

import { useState } from "react";
import CustomBTN from "./CustomBtn";

function Calculator() {
  const [ethCalulate, setEthCalculate] = useState(0);
  const [usdCalulate, setUsdCalculate] = useState(0);
  const [tvlCalculated, setTvlCalculated] = useState(0);
  const [monthCalculated, setMonthCalculated] = useState(0);
  const [yearCalculated, setYearCalculated] = useState(0);
  const handleClick = () => {
    const ethPrice = ethCalulate * 2300;
    setTvlCalculated(((+ethPrice + +usdCalulate) / 1600000000) * 100);
    setMonthCalculated((+ethPrice + +usdCalulate) * 0.0025);
    setYearCalculated((+ethPrice + +usdCalulate) * 0.01);
  };
  return (
    <div className="app-container">
      <div className="container">
        <div className="menu">
          <NavLink to="/">
            <img src={bpholio} />
          </NavLink>
          <NavLink end to="/badge" className="menu-item">
            Badges
          </NavLink>
          <NavLink end to="/calculator" className="menu-item active">
            Calculator
          </NavLink>
          <NavLink end to="/stats" className="menu-item">
            Stats
          </NavLink>
        </div>
        <div className="connect-btn-style">
          <CustomBTN />
        </div>
        <div className="content-calculator">
          <div className="content-calculator-tile">
            Calculate the most important metrics
          </div>
          <div className="container-main">
            <div className="container-left">
              <div className="calculator-item">
                <div className="calculator-text">ETH</div>
                <input
                  onChange={(e) => {
                    setEthCalculate(e.target.value);
                  }}
                  className="calculator-input"
                />
              </div>
              <div className="calculator-item">
                <div className="calculator-text">USDC</div>
                <input
                  onChange={(e) => {
                    setUsdCalculate(e.target.value);
                  }}
                  className="calculator-input"
                />
              </div>

              <div className="calculator-item">
                <div className="calculator-text">% of TVL</div>
                <div className="calculator-input">
                  {tvlCalculated.toFixed(8)}%
                </div>
              </div>
              <div className="calculator-item">
                <div className="calculator-text">1 year income</div>
                <div className="calculator-input">
                  {yearCalculated.toFixed(2)} USDC
                </div>
              </div>
              <div className="calculator-item">
                <div className="calculator-text">1 month income</div>
                <div className="calculator-input">
                  {monthCalculated.toFixed(2)} USDC
                </div>
              </div>
              <div onClick={handleClick} className="btn-calculate">
                Calculate
              </div>
            </div>
            <div className="container-right">
              <div className="right-block-top">
                <div>
                  <span>ETH/usdc</span> &nbsp;— how much funds you want to
                  invest
                </div>
                <div>
                  <span>% of TVL</span> &nbsp; how large is your share of pool
                </div>
              </div>
              <div className="right-block-bottom">
                <div>
                  <span>1 year income</span> &nbsp;— how much you gonna make in
                  1 year of staking
                </div>
                <div>
                  <span>1 month income</span> &nbsp;— same, but 12 times smaller
                </div>
              </div>
            </div>
          </div>
        </div>
        <img src={bgCalculator} />
      </div>
    </div>
  );
}

export default Calculator;
