import { NavLink } from "react-router-dom";
import bgCalculator from "../src/assets/bgCalculator.svg";
import bpholio from "../src/assets/logonew.svg";

import "./App.css";

import { useEffect, useState } from "react";
import CustomBTN from "./CustomBtn";
import { useAccount } from "wagmi";
import { api } from "./App";

function Stats() {
  const { address } = useAccount();
  const [adressData, setAdressData] = useState("");
  const [defaultAdress, setDefaultAdress] = useState("");

  useEffect(() => {
    if (address) {
      api.get("api/stats?address=" + address).then((res) => {
        console.log(res.data);
        setAdressData(res.data);
        if (res.data.usd > 100 || res.data.eth > 1) {
          api
            .post("api/whitelist", {
              address: address,
            })
            .then((res1) => {
              console.log(res1.data);
            });
        }
      });
    }
    if (defaultAdress) {
      api.get("api/stats?address=" + defaultAdress).then((res) => {
        console.log(res.data);
        setAdressData(res.data);
        if (res.data.usd > 100 || res.data.eth > 1) {
          console.log(true);
          api
            .post("api/whitelist", {
              address: defaultAdress,
            })
            .then((res1) => {
              console.log(res1.data);
            });
        }
      });
    }
  }, [address, defaultAdress]);

  return (
    <div className="app-container">
      <div className="container">
        <div className="menu">
          <NavLink to="/">
            <img src={bpholio} />
          </NavLink>
          <NavLink end to="/badge" className="menu-item">
            Badges
          </NavLink>
          <NavLink end to="/calculator" className="menu-item">
            Calculator
          </NavLink>
          <NavLink end to="/stats" className="menu-item active">
            Stats
          </NavLink>
        </div>
        <div className="connect-btn-style">
          <CustomBTN />
        </div>
        <div className="content-stats">
          <div className="content-calculator-tile">personal stats</div>
          <div className="content-calculator-desc">
            Connect your wallet or input address to see your personal stats
          </div>
          {address ? (
            <span className="adress-stats"> {address}</span>
          ) : (
            <div className="stats-input">
              <input
                onChange={(e) => {
                  setDefaultAdress(e.target.value);
                }}
                value={defaultAdress}
              />
            </div>
          )}
          <div className="stats-container">
            <span className="stats-span">your stats</span>
            <div className="stats-items">
              <div className="stat-items">
                <span>ETH</span>
                <span>{adressData?.eth || "0000"}</span>
              </div>
              <div className="stat-items">
                <span>USDC</span>
                <span>{adressData?.usd || "0000"}</span>
              </div>
              <div className="stat-items">
                <span>% OF TVL</span>
                <span>
                  {Number(adressData?.tvl)
                    .toFixed(8)
                    .replace(/\.?0+$/, "") || "00,00"}
                  %
                </span>
              </div>
              <div className="stat-items">
                <span>staking profits</span>
                <span>{adressData?.year?.toFixed(6) + " ETH" || "0000"}</span>
              </div>
            </div>
          </div>
        </div>
        <img src={bgCalculator} />
      </div>
    </div>
  );
}

export default Stats;
