import { NavLink } from "react-router-dom";
import bgCalculator from "../src/assets/bgCalculator.svg";
import eth1 from "../src/assets/1.png";
import eth2 from "../src/assets/2.png";
import eth3 from "../src/assets/3.png";
import usd100 from "../src/assets/4.png";
import usd1000 from "../src/assets/5.png";
import usd10000 from "../src/assets/6.png";
import day1 from "../src/assets/7.png";
import day7 from "../src/assets/8.png";
import day30 from "../src/assets/9.png";
import bpholio from "../src/assets/logonew.svg";
import "./App.css";
import { useContractWrite, usePrepareContractWrite, useAccount } from "wagmi";
import contractABI from "./assets/abi.json"; // Path to your contract ABI
import CustomBTN from "./CustomBtn";
import { useEffect, useState } from "react";
import { api } from "./App";
import { ethers } from "ethers";

const contractAddress = "0x8E9b930079c727A2902C4c91Ec01AB56Ca56AC86";

function daysAgo(unixTimestamp) {
  const oneDayInSeconds = 86400; // 24 hours * 60 minutes * 60 seconds
  const currentDateInSeconds = Math.floor(new Date().getTime() / 1000); // Current date in seconds
  const timestampInSeconds = unixTimestamp; // Assuming the given timestamp is already in seconds
  const differenceInSeconds = currentDateInSeconds - timestampInSeconds;
  const differenceInDays = Math.floor(differenceInSeconds / oneDayInSeconds);
  return differenceInDays;
}

function Badges() {
  const { address } = useAccount();
  const [adressData, setAdressData] = useState("");
  const [defaultAdress, setDefaultAdress] = useState("");
  const [loading, setLoading] = useState(true);

  // const { config } = useContractWrite({
  //   addressOrName: contractAddress,
  //   contractInterface: contractABI,
  //   functionName: "mint",
  //   args: [1000000, address, 1], // Example args: `to` address and `badgeTypeId`
  //   overrides: {
  //     value: "10000000000000000", // Example payable amount in wei (0.01 ETH)
  //   },
  // });

  const {
    write,
    data,
    isLoading,
    isSuccess,
    error: writeError,
  } = useContractWrite({
    ...{
      address: contractAddress,
      abi: contractABI,
      functionName: "mint",
      args: [address, 1],
      value: ethers.parseEther("0.0001"),
      overrides: {
        value: 10000000000000000,
      },
    },
    onError(error) {
      console.error("Contract write error:", error);
    },
    onSuccess(data) {
      console.log("Contract write success:", data);
    },
  });

  useEffect(() => {
    if (address) {
      api.get("api/stats?address=" + address).then((res) => {
        console.log(res.data);
        setAdressData(res.data);
        if (res.data.usd > 100 || res.data.eth > 1) {
          api
            .post("api/whitelist", {
              address: address,
            })
            .then((res1) => {
              setLoading(false);
              console.log(res1.data);
            });
        } else {
          setLoading(false);
        }
      });
    }
  }, [address]);

  return (
    <div className="app-container">
      <div className="container">
        <div className="menu">
          <NavLink to="/">
            <img src={bpholio} />
          </NavLink>
          <NavLink end to="/badge" className="menu-item active">
            Badges
          </NavLink>
          <NavLink end to="/calculator" className="menu-item">
            Calculator
          </NavLink>
          <NavLink end to="/stats" className="menu-item">
            Stats
          </NavLink>
        </div>
        <div className="connect-btn-style">
          <CustomBTN />
        </div>
        {loading ? (
          <>
            <div className="content-stats">
              {!address && (
                <span className="menu-item">Please connect your wallet</span>
              )}
              <div class="lds-dual-ring"></div>
            </div>
          </>
        ) : (
          <>
            {adressData?.eth < 1 && adressData?.usd < 100 ? (
              <>
                {" "}
                {address && (
                  <div className="content-stats">
                    <span className="menu-item">
                      Sorry, you are not eligible for claiming badges
                    </span>
                  </div>
                )}
              </>
            ) : (
              <div className="content-stats">
                <div className="content-calculator-tile">
                  Claim badges for blast activity
                </div>
                <div className="container-nfts">
                  <div className="nft-badge">
                    <img src={eth1} />
                    <div className="text-nft">Bridged 1 ETH</div>
                    <button
                      onClick={() => {
                        if (adressData?.eth >= 1) {
                          write?.({
                            args: [address, 1],
                          });
                        }
                      }}
                      className="btn-min"
                    >
                      Claim
                    </button>
                  </div>
                  <div className="nft-badge">
                    <img src={eth2} />
                    <div className="text-nft">Bridged 5 ETH</div>
                    <button
                      onClick={() => {
                        if (adressData?.eth >= 5) {
                          write?.({
                            args: [address, 2],
                          });
                        }
                      }}
                      className="btn-min"
                    >
                      Claim
                    </button>
                  </div>
                  <div className="nft-badge">
                    <img src={eth3} />
                    <div className="text-nft">Bridged 10 ETH</div>
                    <button
                      onClick={() => {
                        if (adressData?.eth >= 10) {
                          write?.({
                            args: [address, 3],
                          });
                        }
                      }}
                      className="btn-min"
                    >
                      Claim
                    </button>
                  </div>
                  <div className="nft-badge">
                    <img src={usd100} />
                    <div className="text-nft">Bridged 100 USDC</div>
                    <button
                      onClick={() => {
                        if (adressData?.usd >= 100) {
                          write?.({
                            args: [address, 4],
                          });
                        }
                      }}
                      className="btn-min"
                    >
                      Claim
                    </button>
                  </div>
                  <div className="nft-badge">
                    <img src={usd1000} />
                    <div className="text-nft">Bridged 1000 USDC</div>
                    <button
                      onClick={() => {
                        if (adressData?.usd >= 1000) {
                          write?.({
                            args: [address, 5],
                          });
                        }
                      }}
                      className="btn-min"
                    >
                      Claim
                    </button>
                  </div>
                  <div className="nft-badge">
                    <img src={usd10000} />
                    <div className="text-nft">Bridged 10000 USDC</div>
                    <button
                      onClick={() => {
                        if (adressData?.usd >= 10000) {
                          write?.({
                            args: [address, 6],
                          });
                        }
                      }}
                      className="btn-min"
                    >
                      Claim
                    </button>
                  </div>
                  <div className="nft-badge">
                    <img src={day1} />
                    <div className="text-nft">
                      {" "}
                      Bridged moren than 1 day ago{" "}
                    </div>
                    <button
                      onClick={() => {
                        if (daysAgo(adressData["first-date"]) >= 1) {
                          write?.({
                            args: [address, 7],
                          });
                        }
                      }}
                      className="btn-min"
                    >
                      Claim
                    </button>
                  </div>
                  <div className="nft-badge">
                    <img src={day7} />
                    <div className="text-nft">
                      {" "}
                      Bridged moren than 1 week ago
                    </div>
                    <button
                      onClick={() => {
                        if (daysAgo(adressData["first-date"]) >= 7) {
                          write?.({
                            args: [address, 8],
                          });
                        }
                      }}
                      className="btn-min"
                    >
                      Claim
                    </button>
                  </div>
                  <div className="nft-badge">
                    <img src={day30} />
                    <div className="text-nft">
                      Bridged moren than 1 month ago
                    </div>
                    <button
                      onClick={() => {
                        if (daysAgo(adressData["first-date"]) >= 30) {
                          write?.({
                            args: [address, 9],
                          });
                        }
                      }}
                      className="btn-min"
                    >
                      Claim
                    </button>
                  </div>
                  {/* <button disabled={!write} onClick={() => write?.()}>
            Feed
          </button>
          {isLoading && <div>Check Wallet</div>}
          {isSuccess && <div>Transaction: {JSON.stringify(data)}</div>} */}
                </div>
              </div>
            )}
          </>
        )}

        <img src={bgCalculator} />
      </div>
    </div>
  );
}

export default Badges;
