import mainPage from "../src/assets/mainPage.png";
import bpholio from "../src/assets/logonew.svg";

import "./App.css";
import { useState } from "react";
import { NavLink } from "react-router-dom";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import "@rainbow-me/rainbowkit/styles.css";
import CustomBTN from "./CustomBtn";

//   '0.00000022'

function Main() {
  return (
    <div className="app-container">
      <div className="container">
        <div className="menu">
          <NavLink to="/">
            <img src={bpholio} />
          </NavLink>
          <NavLink end to="/badge" className="menu-item">
            Badges
          </NavLink>
          <NavLink end to="/calculator" className="menu-item">
            Calculator
          </NavLink>
          <NavLink end to="/stats" className="menu-item">
            Stats
          </NavLink>
        </div>
        <div className="connect-btn-style">
          <CustomBTN />
        </div>
        <div className="content">
          <div className="content-tile">
            Blastfolio — tool for real Blast users
          </div>
          <div className="content-desc">
            Track your on-chain achievements, calculate potential profits, and
            get maximum from Blast
          </div>
        </div>
        <img src={mainPage} />
      </div>
    </div>
  );
}

export default Main;
