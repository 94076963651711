import "./App.css";
import Main from "./Main";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import axios from "axios";
import Calculator from "./Calculator";
import Stats from "./Stats";
import Badges from "./Badges";
import { getDefaultWallets, RainbowKitProvider } from "@rainbow-me/rainbowkit";
import { configureChains, createConfig, WagmiConfig } from "wagmi";
import { mainnet, polygon, optimism, arbitrum, base, zora } from "wagmi/chains";
import { publicProvider } from "wagmi/providers/public";
import { jsonRpcProvider } from "wagmi/providers/jsonRpc";
const walletCloudApi = "30de8a39a18cdf0e6b86e2d84aca252c";

export const axiosConfig = {
  baseURL: "https://blast-folio.org/",
  timeout: 1000000,
  headers: {
    "Content-Type": "application/json",
  },
};

export const api = axios.create(axiosConfig);

const blastSepolia = {
  id: 168587773, // Chain ID
  name: "Blast Sepolia", // Network Name
  network: "blastSepolia",
  nativeCurrency: {
    name: "Ether",
    symbol: "ETH", // Currency Symbol
    decimals: 18,
  },
  rpcUrls: {
    default: "https://sepolia.blast.io", // RPC Endpoint
  },
  blockExplorers: {
    default: { name: "Blastscan", url: "https://testnet.blastscan.io" }, // Block Explorer
  },
};

// Configure your custom chain using your custom RPC
const { chains, provider, publicClient } = configureChains(
  [blastSepolia],
  [jsonRpcProvider({ rpc: (chain) => ({ http: chain.rpcUrls.default }) })]
);

// const { chains, publicClient } = configureChains(
//   [mainnet, polygon, optimism, arbitrum, base, zora],
//   [publicProvider()]
// );

const { connectors } = getDefaultWallets({
  appName: "My RainbowKit App",
  projectId: walletCloudApi,
  chains,
});

const wagmiConfig = createConfig({
  autoConnect: false,
  connectors,
  publicClient,
});

function App() {
  return (
    <WagmiConfig config={wagmiConfig}>
      <RainbowKitProvider chains={chains}>
        <BrowserRouter>
          {/* <WalletConnectionProvider> */}
          <Routes>
            <Route path="/" element={<Main />} />
            <Route path="/calculator" element={<Calculator />} />
            <Route path="/stats" element={<Stats />} />
            <Route path="/badge" element={<Badges />} />
          </Routes>
          {/* </WalletConnectionProvider> */}
        </BrowserRouter>
      </RainbowKitProvider>
    </WagmiConfig>
  );
}

export default App;
